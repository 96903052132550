import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Header } from 'Components/Header/Header';
import {AppWrapper} from 'Components/AppWrapper/AppWrapper'
import { LogoutButton } from 'Components/LogoutButton/LogoutButton';
import { BubbleBackground } from 'Components/BubleBackground/BubbleBackground';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
    <BubbleBackground />
      <Header logoutButton={<LogoutButton/>}/>
      <AppWrapper>
        <App />
      </AppWrapper>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.debug);
