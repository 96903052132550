import { Button } from "Components/Button/Button";
import { useNavigate } from "react-router-dom";

export const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    navigate("/logout");
  };

  return <Button onClick={handleLogoutClick}>LOGOUT</Button>;
};
