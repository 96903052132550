import React, { ReactNode } from 'react'
import './styles.css'
import clsx from 'clsx'

export type TPageProps = {
    children?: ReactNode
    middle?: boolean
    opaque?: boolean
}
export const Page : React.FC<TPageProps> = ({children, middle = false, opaque = true}) => {
    return <div className={clsx('page', middle && 'content-middle', opaque && 'opaque')}>
        {children}
    </div>
}