import React, { ReactNode } from 'react'
import './styles.css'
import clsx from 'clsx'

export type TContentProps = {
    children?: ReactNode
    middle?: boolean
}

export const Content: React.FC<TContentProps> = ({children, middle=false}) => {
    return <div className={clsx('content', middle && 'content-middle')}>{children}</div>
}