import { PageLoader } from "Components/PageLoader/PageLoader";
import { useEffect, useMemo, useState } from "react";
import { Page } from "Components/Page/Page";
import { Content } from "Components/Content/Content";

export const Logout = () => {
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const [loggingOut, setLoggingOut] = useState(true);
  useEffect(() => {
    async function logout() {
      try {
        const request = await fetch("https://auth.skylark.cloud/auth/logout", {
          method: "GET",
        });
        await request.json();
        setLoggingOut(false);
      } catch (error) {
        console.log(error);
      }
    }

    logout();
  }, []);

  useEffect(() => {
    const redirect = params.get("redirect");
    !loggingOut && redirect && (window.location.href = redirect);
  }, [loggingOut, params]);

  return (
    <Page>
      <Content middle>
        {loggingOut ? <PageLoader transparent={false} /> : <p>Logged Out!</p>}
      </Content>
    </Page>
  );
};
