import "./styles.css";
import { auth, provider } from "Firebase/config";
import { signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import React, { useMemo } from "react";

const login = async (idToken: string) => {
  const request = await fetch("https://auth.skylark.cloud/auth/login/id", {
    method: "POST",
    body: JSON.stringify({ idToken }),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });

  const response = await request.json();
  return response;
};

export const GoogleSignIn = () => {
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const handleLogin = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const idToken = await result.user.getIdToken();
        const response = await login(idToken);
        const token = response.token;
        console.log(token);
        console.log("Logging out...");
        await signOut(auth);
        console.log("Redirecting...");
        const redirect = params.get("redirect");
        redirect && (window.location.href = `${redirect}?token=${token}`);
      })
      .catch(async (error) => {
        await signOut(auth);
        console.log(error);
        GoogleAuthProvider.credentialFromError(error);
      });
  };

  return (
    <button className="cssbuttons-io-button" onClick={handleLogin}>
      {" "}
      Login With Google
      <div className="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            fill="currentColor"
            d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
          ></path>
        </svg>
      </div>
    </button>
  );
};
