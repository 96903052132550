import { Loader } from "Components/Loader/Loader";
import React, { ReactNode } from "react";
import "./styles.css";

export type THeaderProps = {
  logoutButton?: null | undefined | ReactNode | JSX.Element;
};

export const Header: React.FC<THeaderProps> = ({ logoutButton = null }) => {
  return (
    <div className="header">
      <div className="logo-container">
        <Loader logo animate={false} />
        <h2 style={{ color: "var(--app-theme)", padding: "0 8px" }}>
          skylark.cloud
        </h2>
      </div>
      {logoutButton ? logoutButton : <></>}
    </div>
  );
};
