import React, { ComponentProps } from "react";
import "./styles.css"
export type THeroProps = ComponentProps<"p"> & {
  text : string
}

export const Hero: React.FC<THeroProps> = ({text}) => {
  return (
    <div>
      <p className="hero">{text}</p>
    </div>
  );
};
