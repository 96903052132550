import { Page } from "Components/Page/Page";
import { GoogleSignIn } from "Features/GoogleSignIn/GoogleSignIn";
import { Hero } from "Components/Hero/Hero";
import React, { useEffect, useMemo } from "react";
import { Content } from "Components/Content/Content";

const check = async () => {
  const request = await fetch("https://auth.skylark.cloud/auth/check", {
    method: "GET",
    credentials: "include",
  });

  const response = request.json();
  return response;
};

export const Login = () => {
  const [loading, setLoading] = React.useState(true);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  useEffect(() => {
    console.log("Checking Auth");
    check()
      .then((response) => {
        if (response.error) {
          setLoading(false);
        } else {
          setLoading(false);
          const token = response.token;
          console.log(token);
          if (!token) {
            console.log(response);
            setIsLoggedIn(false);
          } else {
            setIsLoggedIn(true);
            const redirect = params.get("redirect");
            redirect && (window.location.href = `${redirect}?token=${token}`);
          }
        }
      })
      .catch((error) => console.log(error));
  }, [params]);

  return (
    <Page opaque={false}>
      <Content middle>
        <Hero
          text={"Explore our wide range of tools and access all our apps"}
        />
        {loading ? (
          "checking auth"
        ) : (
          <>{isLoggedIn ? `Logged In` : <GoogleSignIn />}</>
        )}
      </Content>
    </Page>
  );
};
