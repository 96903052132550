import React from "react";
import "./App.css";
import { Logout } from "Features/Logout/Logout";
import { Routes, Route } from "react-router-dom";
import { Login } from "Features/Login/Login";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
}

export default App;
